import React, { useState } from "react";
import {
  PivotViewComponent,
  Inject,
  FieldList,
  Toolbar,
  PDFExport,
  ExcelExport,
  GroupingBar
} from '@syncfusion/ej2-react-pivotview';

import { Spin, Button, DatePicker } from "antd";
import { LoadingOutlined } from "@ant-design/icons";


const antIcon = (<LoadingOutlined style={{ fontSize: 24 }} spin />);
const { RangePicker } = DatePicker;

function Reports() {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  let dataSourceSettings = {
    enableSorting: true,
    valueSortSettings: { headerDelimiter: ' - ' },
    values: [
      { name: 'Шумора', caption: 'Шумора' },
      { name: 'Маблағ', caption: 'Маблағ' }
    ],
    dataSource: tableData,
    rows: [
        { name: 'Area', caption: 'Вилоят' },
        { name: 'Region', caption: 'Ноҳия/шаҳр' },
        { name: 'Performer', caption: 'Иҷрочи' },
        { name: 'Хизматрасонӣ' }
    ],
    columns: [

    ],
    filters: [

    ],
    formatSettings: [
      { name: 'Шумора', format: '# 0' },
      { name: 'Маблағ', format: '# 0.00' }
    ],
    expandAll: false,
  };

  let hyperlinkSettings = {
    showHyperlink: false,
    showRowHeaderHyperlink: false,
    showColumnHeaderHyperlink: false,
    showValueCellHyperlink: false,
    showSummaryCellHyperlink: true,
    headerText: undefined,
    conditionalSettings: []
  };

  // Загрузка данных
  const handleLoadData = () => {
    if (!startDate || !endDate) {
      setTableData([]);
      return;
    }

    setLoading(true);
    const query = `?start=${startDate}&end=${endDate}`;

    fetch(`https://smartpay.tj/api/reports/adliya/pivot${query}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + window.sessionStorage["access_token"]
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setTableData(data.rows || []);
      })
      .catch((error) => {
        console.error("Ошибка при загрузке данных:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Обработка изменений дат
  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setStartDate(dates[0].format("YYYY-MM-DD"));
      const endPlusOne = dates[1].clone().add(1, "day").format("YYYY-MM-DD");
      setEndDate(endPlusOne);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  function cellClick(args) {
    //triggers for every cell click in pivot table
    //args.currentCell.setAttribute("style", "background-color: red;");
    console.log(args);
  } 

  return (
    <div className="w-full pl-2" style={{ minHeight: 600 }}>
      {/* Выбор периода и кнопка */}
      <div className="mb-4">
        <RangePicker className="w-1/3 m-4" onChange={handleDateChange} />
        <Button
          type="primary"
          style={{ marginLeft: 8 }}
          onClick={handleLoadData}
        >
          Гирифтани маълумот
        </Button>
      </div>

      {loading ? (
        <Spin indicator={antIcon} />
      ) : tableData.length === 0 ? (
        <div className="w-full text-center text-slate-500">
          Барои намоиши ҳисобот, давраро интихоб намоед.
        </div>
      ) : (
        <div className="control-pane">
          <div className="control-section">
            <PivotViewComponent
              id="PivotView"
              locale="tg"
              dataSourceSettings={dataSourceSettings}
              width={"100%"}
              height={"800"}
              hyperlinkSettings={hyperlinkSettings} 
              hyperlinkCellClick={cellClick.bind(this)}
              gridSettings={{
                columnWidth: 120,
                allowResizing: true
              }}
              showToolbar={true}
              toolbar={['Export', 'FieldList', 'PdfExport', 'ExcelExport']}
              showFieldList={true}
              showGroupingBar={true}
              enableFieldSearching={true}
              allowExcelExport={true}
              allowPdfExport={true}
              enableValueSorting={true}
              chartSettings={{ title: `Ҳисоботи пардохто ${startDate} - ${endDate}`}}
              >
              {/* Подключаем нужные сервисы (Toolbar, ExcelExport, PDFExport и т.д.) */}
              <Inject services={[FieldList, Toolbar, PDFExport, ExcelExport, GroupingBar]} />
            </PivotViewComponent>
          </div>
        </div>
      )}
    </div>
  );
}

export default Reports;
