import React, { Fragment, useEffect, useState } from "react";
import i18n, { t } from "i18next";
import { errorHandler, withRouter } from "../../utils/GlobalFunctions";
import InputMask from "react-input-mask";
import { Form, Input, Button, Select, Table, Modal, Checkbox, message } from "antd";
import { LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { isMobile } from "react-device-detect";

const { Search } = Input;

function FillForm(props) {
  // ---------- 1) Аналоги this.state ----------
  const [formId, setFormId] = useState(null);
  const [elemService, setElemService] = useState([]);
  const [elemVAT, setElemVAT] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [form] = Form.useForm();

  const { location, navigate } = props;
  const { formItem } = location.state;       // как и раньше: this.props.location.state.formItem
  const { translation } = props;             // как и раньше: this.props.translation или t(...)

  // ---------- 2) Аналог componentDidMount ----------
  useEffect(() => {
    setDataFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ---------- 3) Аналог метода setData ----------
  const setDataFn = () => {
    const form = formItem;
    let newArr = [];
    let vatArr = [];
    let sum = 0;

    setFormId(form.form_id);

    form.services.forEach((elem, id) => {
      newArr.push({
        id: elem.service_id,
        qty: elem.unit_qty,
        dsc: null,
        price: (elem.amount / 100).toFixed(2),
        total: elem.selectable
          ? (0).toFixed(2)
          : (elem.unit_qty * (elem.amount / 100)).toFixed(2),
      });
      sum += parseFloat(newArr[id].total);

      if (elem.vat) {
        vatArr.push({
          id: elem.vat.service_id,
          qty: elem.vat.unit_qty / 100,
          name: elem.vat.name,
          is_tax: elem.vat.is_tax,
          dsc: null,
          price: ((elem.amount * elem.vat.value) / 100).toFixed(2),
          total: (
            (elem.amount * elem.unit_qty * elem.vat.unit_qty * elem.vat.value) /
            100
          ).toFixed(2),
        });
        sum += parseFloat(vatArr[id].total);
      }
    });

    setElemService(newArr);
    setElemVAT(vatArr);
    setGrandTotal(sum);
    setIsLoaded(true);
  };

  // ---------- 4) Аналог onFinish ----------
  const onFinish = (fieldsValue) => {
    setSubmitButtonDisabled(true);

    // Отбираем сервисы, где total != 0.00
    const filteredServices = elemService.filter((elem) => elem.total !== "0.00");

    // Извлекаем поля формы
    const phonenumber = fieldsValue["phonenumber"];
    const val = { ...fieldsValue };
    val["Плательщик"] = fieldsValue["customer"];
    val["РМА"] = fieldsValue["tin"];
    delete val["customer"];
    delete val["tin"];
    delete val["phonenumber"];

    // Отправляем запрос
    fetch(`${process.env.REACT_APP_API_URL}invoices/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.sessionStorage["access_token"],
      },
      body: JSON.stringify({
        form_id: formId,
        bill_to: val,
        customer_phonenumber: phonenumber,
        services: filteredServices.concat(elemVAT),
      }),
    })
      .then((response) => {
        return errorHandler(response, "FillForm.jsx - onFinish", navigate);
      })
      .then((responseJson) => {
        // Сбрасываем форму
        form.resetFields();

        // Показываем уведомление
        Modal.info({
          title: "Ҳисобномаи нав сохта шуд!",
          content: (
            <div>
              <div className="font-bold">{`№ ${responseJson.invoice_number}`}</div>
              <div className="text-justify">
                {`${responseJson.subject[i18n.language]}`}
              </div>
              <div className="font-bold">
                {`${(responseJson.amount_due / 100).toFixed(2)} сомони`}
              </div>
            </div>
          ),
        });

        // Переходим на страницу
        navigate("/payments/forms");
      });
  };

  // ---------- 5) Аналог handleSearchTin ----------
  const handleSearchTin = async (value) => {
    if (!/^\d{9}$/.test(value)) {
      message.error("РМА должен содержать ровно 9 цифр");
      return;
    }

    try {
      if (!value) return;

      const url = `${process.env.REACT_APP_API_URL}invoices/tin/verify/${value}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + window.sessionStorage["access_token"],
        },
      });

      if (!response.ok) {
        throw new Error("Ошибка при проверке TIN");
      }

      const data = await response.json();

      if (data.errorCode === 0) {
        const customer = data.FullName || '';
        form.setFieldsValue({ customer });
      } else {
        alert("Не удалось найти ФИО для данного РМА. Заполните пожалуйста вручную");
      }
    } catch (err) {
      console.error(err);
      message.error("Ошибка при получении данных");
    }
  };

  // ---------- 6) Формируем столбцы таблицы (form_columns) ----------
  const form_columns = [
    {
      title: translation("DESCRIPTION"),
      width: "54%",
      dataIndex: "name",
      key: "name",
      render: (name, row, rowIndex) => {
        return (
          <div>
            {row.selectable ? (
              <Checkbox
                value={row.key}
                onChange={(e) => {
                  const newArr = [...elemService];
                  let sum = 0;
                  let q_ty = 0;

                  if (e.target.checked) {
                    q_ty = parseFloat(newArr[rowIndex].qty);
                  } else {
                    q_ty = 0;
                  }

                  newArr[rowIndex].total = (
                    q_ty * parseFloat(newArr[rowIndex].price)
                  ).toFixed(2);

                  newArr.forEach((num) => {
                    sum += parseFloat(num.total);
                  });

                  setElemService(newArr);
                  setGrandTotal(sum);
                }}
              >
                {name}
              </Checkbox>
            ) : (
              <div className="w-full">{name}</div>
            )}

            {row.vat ? (
              <div className="md:ml-[4rem] py-2">{row.vat.name}</div>
            ) : null}
          </div>
        );
      },
    },
    {
      title: translation("MEASUREMENT"),
      width: "8%",
      align: "center",
      dataIndex: "unit_name",
      key: "unit_name",
      render: (unit_name, row) => {
        return (
          <div>
            <div className="w-full flex justify-center">{unit_name}</div>
            {row.vat ? (
              <div className="py-2">{row.vat.unit_name}</div>
            ) : null}
          </div>
        );
      },
    },
    {
      title: translation("QTY"),
      width: "8%",
      dataIndex: "unit_qty",
      key: "unit_qty",
      render: (unit_qty, row, rowIndex) => {
        const q_ty = elemService[rowIndex]?.qty;

        if (row.discount !== null) {
          // Выбор со скидкой
          return (
            <div>
              <Select
                className="text-center"
                id={"qty_" + row.service_id}
                defaultValue="1"
                style={
                  isMobile
                    ? { fontSize: 12, width: 80 }
                    : { fontSize: 12, width: 140 }
                }
                onChange={(value, option) => {
                  let newArr = [...elemService];
                  let sum = 0;
                  newArr[rowIndex].qty = 1 - value;
                  newArr[rowIndex].dsc = value !== 0 ? option.label : null;
                  newArr[rowIndex].total = (
                    (parseFloat(newArr[rowIndex].qty) || 0) *
                    parseFloat(newArr[rowIndex].price)
                  ).toFixed(2);

                  newArr.forEach((num) => {
                    sum += parseFloat(num.total);
                  });

                  setElemService(newArr);
                  setGrandTotal(sum);
                }}
                options={row.discount}
              />

              {row.vat ? (
                <div className="w-full flex justify-center py-2">
                  {(elemVAT[rowIndex]?.qty * 100).toFixed(2)}
                </div>
              ) : null}
            </div>
          );
        } else if (row.unit_qty === 0) {
          // Можно вводить qty вручную
          return (
            <div>
              <input
                id={"qty_" + rowIndex}
                className="text-center bg-white border px-2 py-1 rounded-[4px] w-full mx-auto"
                name="price"
                defaultValue={q_ty}
                onChange={(e) => {
                  let newArr = [...elemService];
                  let vatArr = [...elemVAT];
                  let sum = 0;

                  newArr[rowIndex].qty = e.target.value;
                  newArr[rowIndex].total = (
                    (parseFloat(newArr[rowIndex].qty) || 0) *
                    parseFloat(newArr[rowIndex].price)
                  ).toFixed(2);

                  newArr.forEach((num) => {
                    sum += parseFloat(num.total);
                  });

                  if (row.vat) {
                    vatArr[rowIndex].price = (
                      parseFloat(newArr[rowIndex].price * row.vat.value) *
                      parseFloat(newArr[rowIndex].qty)
                    ).toFixed(2);
                    vatArr[rowIndex].total = (
                      parseFloat(vatArr[rowIndex].price) *
                      parseFloat(vatArr[rowIndex].qty)
                    ).toFixed(2);
                  }

                  vatArr.forEach((num) => {
                    sum += parseFloat(num.total);
                  });

                  setElemService(newArr);
                  setElemVAT(vatArr);
                  setGrandTotal(sum);
                }}
              />

              {row.vat ? (
                <div className="w-full flex justify-center py-2">
                  {(elemVAT[rowIndex]?.qty * 100).toFixed(2)}
                </div>
              ) : null}
            </div>
          );
        } else {
          // qty фиксированное
          return (
            <div>
              <div className="w-full flex justify-center">{q_ty}</div>
              {row.vat ? (
                <div className="w-full flex justify-center py-2">
                  {(elemVAT[rowIndex]?.qty * 100).toFixed(2)}
                </div>
              ) : null}
            </div>
          );
        }
      },
    },
    {
      title: translation("PRICE"),
      width: "15%",
      align: "right",
      dataIndex: "amount",
      key: "price",
      render: (amount, row, rowIndex) => {
        const price = elemService[rowIndex]?.price;
        if (row.amount === 0) {
          // Можно вводить price вручную
          return (
            <div>
              <input
                id={"price_" + rowIndex}
                className="text-right bg-white border px-2 py-1 rounded-[4px] w-full mx-auto"
                name="price"
                defaultValue={price}
                onChange={(e) => {
                  let newArr = [...elemService];
                  let vatArr = [...elemVAT];
                  let sum = 0;

                  newArr[rowIndex].price = e.target.value;
                  newArr[rowIndex].total =
                    (parseFloat(newArr[rowIndex].price) || 0) *
                    parseFloat(newArr[rowIndex].qty);

                  newArr.forEach((num) => {
                    sum += parseFloat(num.total);
                  });

                  if (row.vat) {
                    vatArr[rowIndex].price = newArr[rowIndex].price * row.vat.value;
                    vatArr[rowIndex].total = (
                      parseFloat(vatArr[rowIndex].price) *
                      parseFloat(vatArr[rowIndex].qty)
                    ).toFixed(2);
                  }

                  vatArr.forEach((num) => {
                    sum += parseFloat(num.total);
                  });

                  setElemService(newArr);
                  setElemVAT(vatArr);
                  setGrandTotal(sum);
                }}
              />
              {row.vat ? (
                <div className="w-full flex justify-end py-2">
                  {parseFloat(elemVAT[rowIndex]?.price).toFixed(2)}
                </div>
              ) : null}
            </div>
          );
        } else {
          // price фиксированное
          return (
            <div>
              <div className="w-full flex justify-end">
                {elemService[rowIndex]?.price}
              </div>
              {row.vat ? (
                <div className="w-full flex justify-end py-2">
                  {parseFloat(elemVAT[rowIndex]?.price).toFixed(2)}
                </div>
              ) : null}
            </div>
          );
        }
      },
    },
    {
      title: translation("SUM"),
      width: "15%",
      align: "right",
      dataIndex: "amount",
      key: "amount",
      render: (amount, row, rowIndex) => {
        return (
          <div>
            <div className="w-full flex justify-end">
              {elemService[rowIndex]?.total}
            </div>
            {row.vat ? (
              <div className="w-full flex justify-end py-2">
                {elemVAT[rowIndex]?.total}
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  // ---------- 7) Рендер ----------
  if (!formItem && !isLoaded) {
    return (
      <div className="w-full flex justify-center">
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      </div>
    );
  }

  return (
    <div className="block w-full">
      <Form
        form = {form}
        id="myform"
        className="w-full"
        onFinish={onFinish}
      >
        <div className="sticky top-0 z-[99]">
          <div className="bg-[#18526B] flex justify-center p-2 text-white">
            <div className="ml-4 flex text-white items-center sm:text-m md:text-l lg:text-l xl:text-2xl m-0 border-r-2 pr-3">
              {formItem.form_id < 10 ? `0${formItem.form_id}` : formItem.form_id}
            </div>
            <div className="w-full pl-[2rem] flex items-center text-white sm:text-base md:text-lg m-0">
              {formItem.form_name.tj}
            </div>
          </div>

          <div className="w-full mb-[2rem]">
            <div className="sm:block md:flex bg-[#fff] rounded-b-[8px] py-4 shadow-lg">
              {/* Поле TIN */}
              <div className="flex flex-col space-y-2 sm:px-4 md:px-5 lg:px-5 xl:px-8">
                <p className="m-0 text-xs text-[#6A6A6A] pl-3">РМА</p>
                <Form.Item name="tin" className="m-0">
                  <Search
                    required
                    name="tin"
                    placeholder="РМА-и пардохткунанда"
                    maxLength={9}
                    minLength={9}
                    onSearch={(value) => {
                      if (!value || !/^\d{9}$/.test(value)) {
                        alert("РМА должен содержать ровно 9 цифр!");
                        return;
                      }
                      handleSearchTin(value);
                    }}
                  />
                </Form.Item>
              </div>

              {/* Поле Плательщик */}
              <div className="flex flex-col md:w-[30%] space-y-2 sm:px-4 md:px-5 lg:px-5 xl:px-8">
                <p className="m-0 text-xs text-[#6A6A6A] pl-3">
                  {translation("PAYER")}
                </p>
                <Form.Item name="customer" className="m-0">
                  <Input
                    className="bg-white border px-3 py-2 rounded-lg"
                    type="text"
                    name="customer"
                    required
                  />
                </Form.Item>
              </div>

              {/* Поле Номер телефона */}
              <div className="flex flex-col space-y-2 sm:px-4 md:px-5 lg:px-5 xl:px-8">
                <p className="m-0 text-xs text-[#6A6A6A] pl-3">
                  {translation("PHONE_NUMBER")}
                </p>
                <Form.Item
                  name="phonenumber"
                  className="m-0"
                  rules={[
                    {
                      required: true,
                      message: "Номер телефона обязателен",
                    },
                    {
                      pattern: /^[0-9]{9}$/,
                      message: "Номер должен состоять ровно из 9 цифр",
                    },
                  ]}
                >
                  <Input
                    className="bg-white border px-3 py-2 rounded-lg"
                    type="text"
                  />
                </Form.Item>
              </div>

              {/* Дополнительные поля из fill_form */}
              {formItem?.fill_form.map((elem) => {
                if (elem.type === "number") {
                  return (
                    <Fragment key={elem.id}>
                      <div className="flex flex-col space-y-2 sm:px-4 md:px-5 lg:px-5 xl:px-8">
                        <p className="m-0 text-xs text-[#6A6A6A] pl-3">
                          {elem.input}
                        </p>
                        <Form.Item name={elem.input} className="m-0">
                          <InputMask
                            className="bg-white border px-3 py-2 rounded-lg"
                            mask={elem?.mask}
                          />
                        </Form.Item>
                      </div>
                    </Fragment>
                  );
                }

                return (
                  <Fragment key={elem.id}>
                    <div className="flex flex-col space-y-2 sm:px-4 md:px-5 lg:px-5 xl:px-8">
                      <p className="m-0 text-xs text-[#6A6A6A] pl-3">
                        {elem.input}
                      </p>
                      <Form.Item name={elem.input} className="m-0">
                        <Input
                          className="bg-white border px-3 py-2 rounded-lg"
                          type={elem.type}
                          name={elem.input}
                        />
                      </Form.Item>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>

        {/* Таблица */}
        <div className="block w-full">
          <div className="w-[100%] md:px-[2rem]">
            <Table
              footer={() => {
                return (
                  <div className="w-full flex justify-end">
                    <Button
                      htmlType="submit"
                      disabled={!grandTotal}
                      className={`${
                        submitButtonDisabled ? "hidden" : ""
                      } text-white bg-emerald-600 hover:bg-white`}
                    >
                      <div className="flex items-center">
                        <div className="flex mr-4 items-center">
                          <CheckCircleOutlined color="#fff" />
                        </div>
                        <div>{t("CREATE_INVOICE")}</div>
                      </div>
                    </Button>
                  </div>
                );
              }}
              className="shadow-lg"
              columns={
                isMobile
                  ? form_columns.filter((_, index) => index !== 1)
                  : form_columns
              }
              dataSource={formItem.services}
              pagination={false}
              size="small"
              summary={() => {
                return (
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      className="text-right"
                      index={1}
                      colSpan={isMobile ? 3 : 4}
                    >
                      <div className="text-red-500 tracking-wide font-bold uppercase">
                        {`${translation("TOTAL_INVOICE_AMOUNTS")} (${translation(
                          "TJS"
                        )}):`}
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      className="text-right"
                      index={2}
                      colSpan={1}
                    >
                      <div className="text-red-500 tracking-wide font-bold">
                        {grandTotal.toFixed(2)}
                      </div>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                );
              }}
            />
          </div>
        </div>
      </Form>
    </div>
  );
}

// Экспортируем
export default withRouter(FillForm);