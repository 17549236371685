import ReactDOM from "react-dom";
import React  from 'react';
import "./index.css";
import App from "./App";
import './utils/i18n';
import { BrowserRouter } from "react-router-dom";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { registerLicense, setCulture, L10n } from '@syncfusion/ej2-base';

registerLicense(process.env.REACT_APP_LICENSE);
setCulture("tg");

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}> 
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
